import styles from "./ClientDetail.module.scss";
import React, {Component} from "react";
import {loadClientDetail, selectClient} from "../../../store/actions/client";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import {Button, Col, Row} from "react-bootstrap";
import {
    IoPersonCircleOutline,
    IoLocationOutline,
    TiLocationArrowOutline,
    IoIosArrowDropleft,
    AiOutlineMail,
    RiPhoneFill,
    VscEdit
} from "react-icons/all";
import NewClientModal from "../Modals/NewClientModal/NewClientModal";
// Read: https://github.com/elmarquis/Leaflet.GestureHandling/
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import * as L from "leaflet";
import {GestureHandling} from "leaflet-gesture-handling";
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';


let DefaultIcon = L.icon({
    ...L.Icon.Default.prototype.options,
    iconUrl: icon,
    iconRetinaUrl: iconRetina,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;

// Gesture handler
L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);


class ClientDetail extends Component<any, any> {

    state = {
        parentRedirect: false,
        mapPositionLat: 0,
        mapPositionLon: 0
    };

    onOpenNewClientModal = (client: any): void => {
    };

    componentDidMount(): void {
        if (this.props.match.params.id) {
            this.props.onLoadClientDetail(this.props.match.params.id, this.props.token, () => this.notFoundClientErrorHandler(this));
        }
    }


    setSelectedClient() {
        if (this.props.match.params.id) {
            this.props.onSelectClient(this.props.match.params.id);
        }
    }

    onCloseClient() {
        this.props.history.push('/clients');
    }

    componentWillUnmount(): void {
        this.props.onSelectClient(null);
    }

    notFoundClientErrorHandler = (self: any) => {
        self.setState({...this.state, parentRedirect: true})
    };

    onNavigate(client: any) {
        let searchAddress = `https://maps.google.com/?q=${client.address.latitude},${client.address.longitude}`;

        const ua = navigator.userAgent.toLowerCase();
        const isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        if (isAndroid) {
            window.open(`geo:${client.address.latitude},${client.address.longitude}${searchAddress}`, '_blank');
        } else {
            window.open(searchAddress, '_blank');
        }
    };

    render() {
        if (this.state.parentRedirect) {
            return (
                <Redirect to='/clients'/>
            );
        }

        let client = this.props.selectedClient;
        let clientData = (<div>Loading...</div>);
        if (client) {
            let email = null;
            if (client.email) {
                email = (
                    <div>
                        <div className={styles.icon}><AiOutlineMail/></div>
                        <div><a href={`mailto:${client.email}`}>{client.email}</a></div>
                    </div>
                );
            }

            let gsm = null;
            if (client.gsm) {
                gsm = (
                    <div>
                        <div className={styles.icon}><RiPhoneFill/></div>
                        <div><a href={`tel:${client.gsm}`}>{client.gsm}</a></div>
                    </div>
                );
            }

            let address = null;
            if (client.address) {
                const position: L.LatLngTuple = [client.address.latitude, client.address.longitude];
                let mapOptions = {
                    className: styles.clientMap,
                    center: position,
                    zoom: 13,
                    gestureHandling: true,
                    scrollWheelZoom: false,
                    key: position.toString()
                };

                // note 'key' in MapContainer. This will rerender map when the coordinates do change
                address = (
                    <>
                        <div>
                            <div className={styles.icon}><IoLocationOutline/></div>
                            <div>{client.address.fullName}</div>
                        </div>

                        <div>
                            <MapContainer {...mapOptions}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={position}>
                                    <Popup>
                                        {client.address.fullName}
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        </div>

                        <div className={"justify-content-center"}>
                            <Button className={styles.startNavBtn}
                                    onClick={() => this.onNavigate(client)}
                                    variant="success">
                                <span className={styles.btnIcon}><TiLocationArrowOutline/></span>
                                <span>Navigatie starten</span>
                            </Button>
                        </div>
                    </>
                );
            }

            clientData = (
                <div className={styles.clientCard}>
                    <div>
                        <div className={styles.icon}><IoPersonCircleOutline/></div>
                        <div>{client.firstname} {client.lastname}</div>
                    </div>

                    {email}
                    {gsm}
                    {address}
                </div>
            );
        }

        return (
            <>
                <Row>
                    <Col xs={12} className={"pt-2"}>
                        <div className={styles.topBtns}>
                            <Button variant="secondary"
                                    className={"d-flex align-items-center"}
                                    onClick={() => this.onCloseClient()}>
                                <span className={styles.btnIcon}><IoIosArrowDropleft/></span>
                                <span className={"pl-1"}>Klantenlijst</span>
                            </Button>

                            <Button variant="outline-dark"
                                    onClick={() => this.onOpenNewClientModal(client)}
                                    className={"d-flex align-items-center"}>
                                <span><VscEdit/></span>
                            </Button>
                        </div>

                        <h4>Klantgegevens</h4>
                    </Col>
                    <Col xs={12}>
                        <div>{clientData}</div>
                    </Col>
                </Row>
                <NewClientModal onOpen={(click: any) => this.onOpenNewClientModal = click}/>
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        selectedClient: state.client.selectedClient,
        clients: state.client.clients,
        loading: state.client.loading,
        token: state.auth.token
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onLoadClientDetail: (clientId: string, token: string, notFoundClientErrorHandler: any) =>
            dispatch(loadClientDetail(clientId, token, notFoundClientErrorHandler)),
        onSelectClient: (client: any) => dispatch(selectClient(client))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientDetail));
