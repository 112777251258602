import {updateObject} from "../../shared/utility";
import {
    FETCH_ADDRESSES_START,
    FETCH_ADDRESSES_SUCCESS, RESET_ADDRESSES
} from "../actions/actionTypes";

const initialState = {
    addresses: [],
    loading: false
};

const fetchAddressesStart = (state: any, action: any) => {
    return updateObject(state, {loading: true});
};

const fetchAddressesSuccess = (state: any, action: any) => {
    return updateObject(state, {
        addresses: action.addresses,
        loading: false
    });
};


const reducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case FETCH_ADDRESSES_START:
            return fetchAddressesStart(state, action);
        case FETCH_ADDRESSES_SUCCESS:
        case RESET_ADDRESSES:
            return fetchAddressesSuccess(state, action);

        default:
            return state;
    }
};

export default reducer;
