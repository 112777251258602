import {connect} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import style from "../Clients.module.scss";
import {IoPersonAddSharp} from "react-icons/all";
import NewClientModal from "../Modals/NewClientModal/NewClientModal";
import React, {useEffect} from "react";
import {fetchClients} from "../../../store/actions/client";
import {NavLink, withRouter} from "react-router-dom";

let onOpenNewClientModal = (): void => {
};

const ClientsList = (props: any) => {

    let fetchClientProp = props.onFetchClients;
    let token = props.token;
    useEffect(() => {
        fetchClientProp(token);
    }, [fetchClientProp, token]);

    let clients = 'Loading...';
    if (!props.loading) {
        clients = props.clients.map((client: any) => (
            <Row key={client.id}>
                <Col xs={12} className={style.client}>
                    <NavLink className={style.clientContainer}
                             to={props.match.url + "/" + client.id}>
                        {client.lastname} {client.firstname}
                    </NavLink>
                </Col>
            </Row>
        ));
    }

    return (
        <Row>
            <Col xs={12}>
                <div className={style.topBtns}>
                    <h4>Mijn klanten</h4>
                    <Button variant="success" onClick={() => onOpenNewClientModal()}>
                        <span className={"btn-icon"}><IoPersonAddSharp/></span>
                    </Button>
                </div>
            </Col>

            <Col xs={12} className={"client-list"}>
                {clients}
            </Col>

            <NewClientModal onOpen={(click: any) => onOpenNewClientModal = click}/>
        </Row>
    )
};

const mapStateToProps = (state: any) => {
    return {
        clients: state.client.clients,
        loading: state.client.loading,
        selectedClient: state.client.selectedClient,
        token: state.auth.token
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onFetchClients: (token: string) => dispatch(fetchClients(token))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientsList));