import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export default instance;

export const externalInstance = axios.create({
    baseURL: 'https://geocode.search.hereapi.com/v1/geocode'
});