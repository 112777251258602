import axios from '../../axios-settings';
import jwt from 'jwt-decode';

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token: string, user: any) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        user: user
    }
};

export const authFail = (error: any) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const authRefreshFail = (error: any, redirectedFrom: any) => {
    return {
        type: actionTypes.AUTH_REFRESH_FAIL,
        refreshError: error,
        redirectedFrom: redirectedFrom
    }
};

export const removeRedirect = () => {
    return {
        type: actionTypes.AUTH_REMOVE_REDIRECT,
        redirectedFrom: null
    }
};

export const auth = (email: string, password: string) => {
    return (dispatch: any) => {
        dispatch(authStart());
        const authData = {
            email,
            password
        };

        axios.post('/login',
            {...authData},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
            .then(response => {
                let data = response.data;
                handleToken(dispatch, data);
            })
            .catch(err => {
                dispatch(authFail(err));
            })
    }
};

export const autoSignIn = () => {
    return (dispatch: any) => {
        dispatch(silentRefreshToken());
    }
};

export const checkAuthTimeout = (expirationTime: number) => {
    return (dispatch: any) => {
        setTimeout(() => {
            console.log('Expired JWT. Refreshing');
            dispatch(silentRefreshToken());

        }, expirationTime * 1000);
    }
};

const silentRefreshToken = () => {
    return (dispatch: any) => {
        axios.post('/token/refresh',
            {},
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
            .then(response => {
                handleToken(dispatch, response.data);
            })
            .catch(err => {
                let redirectedFrom = window.location.pathname !== '/login' ? window.location.pathname : null;
                dispatch(authRefreshFail(err, redirectedFrom));
            })
    }
};

function handleToken(dispatch: any, data: any) {
    let decodedToken: any = jwt(data.token);
    let currentTimestamp = Math.floor((new Date()).getTime() / 1000);

    dispatch(authSuccess(data.token, data.vendor));

    const minTimeBeforeExpiration = 30;
    dispatch(checkAuthTimeout(decodedToken.exp - currentTimestamp - minTimeBeforeExpiration));
}

export const logout = () => {
    return (dispatch: any) => {
        dispatch(authStart());

        axios.delete('/token/invalidate',
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            })
            .then(response => {
                dispatch(authLogout());
            })
            .catch(err => {
                dispatch(authLogout());
            })
    }
};

export const authLogout = () => {
    window.localStorage.setItem('logout', new Date().toUTCString());

    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const enableSyncLogoutListener = () => {
    return (dispatch: any) => {
        window.addEventListener('storage', (event) => syncLogout(dispatch, event));
    }
};

export const syncLogout = (dispatch: any, event: any) => {
    if (event.key === 'logout') {
        dispatch(authLogout());
    }
}

