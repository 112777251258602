import React, {useEffect, useRef} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import './App.scss';
import Clients from './containers/Clients/Clients';
import Profile from "./containers/Profile/Profile";
import Layout from "./hoc/Layout/Layout";
import Auth from "./containers/Auth/Auth";
import {connect} from "react-redux";
import {autoSignIn, enableSyncLogoutListener, removeRedirect} from "./store/actions/auth";

const App = (props: any) => {

    const enableSyncLogout = props.enableSyncLogoutListener;
    useEffect(() => {
        enableSyncLogout();
    }, [enableSyncLogout]);

    const redirectRef: any = useRef(null);
    useEffect(() => {
        if (redirectRef.current) {
            redirectRef.current = null;
        }

        if (props.isAuthenticated && props.redirectedFrom) {
            redirectRef.current = (<Redirect to={props.redirectedFrom}/>);
            props.onRemoveRedirect();
        }
    });

    if (!props.isAuthenticated) {
        if (!props.refreshError) {
            props.onTryAutoSignIn();
        }

        if (props.loading) {
            return (
                <>
                    Loading...
                </>
            );
        }

        return (
            <>
                <Layout>
                    <Switch>
                        <Route path="/login">
                            <Auth/>
                        </Route>
                    </Switch>
                </Layout>

                <Redirect to='/login'/>
            </>
        );
    }

    return (
        <>
            {redirectRef.current}
            <Layout>
                <Switch>
                    <Route path="/login">
                        <Auth/>
                    </Route>
                    <Route path="/clients">
                        <Clients/>
                    </Route>
                    <Route path="/profile" exact>
                        <Profile/>
                    </Route>
                    <Redirect to='/clients'/>
                </Switch>
            </Layout>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.token !== null,
        loading: state.auth.loading,
        token: state.auth.token,
        refreshError: state.auth.refreshError,
        redirectedFrom: state.auth.redirectedFrom
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onTryAutoSignIn: () => dispatch(autoSignIn()),
        onRemoveRedirect: () => dispatch(removeRedirect()),
        enableSyncLogoutListener: () => dispatch(enableSyncLogoutListener())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
