export const SELECT_CLIENT = 'SELECT_CLIENT';

export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';

export const CREATE_NEW_CLIENT_START = 'CREATE_NEW_CLIENT_START';
export const CREATE_NEW_CLIENT = 'CREATE_NEW_CLIENT';

export const UPDATE_CLIENT_START = 'UPDATE_CLIENT_START';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';

export const FETCH_ADDRESSES_START = 'FETCH_ADDRESS_START';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESS_SUCCESS';
export const FETCH_ADDRESSES_FAIL = 'FETCH_ADDRESS_FAIL';
export const RESET_ADDRESSES = 'RESET_ADDRESSES';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_REFRESH_FAIL = 'AUTH_REFRESH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REMOVE_REDIRECT = 'AUTH_REMOVE_REDIRECT';

export const LOAD_CLIENT_DETAIL_START = 'LOAD_CLIENT_DETAIL_START';
export const LOAD_CLIENT_DETAIL_SUCCESS = 'LOAD_CLIENT_DETAIL_SUCCESS';
export const LOAD_CLIENT_DETAIL_FAIL = 'LOAD_CLIENT_DETAIL_FAIL';

export const DELETE_CLIENT_START = 'DELETE_CLIENT_START';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';