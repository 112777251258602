import {updateObject} from "../../shared/utility";
import * as actionTypes from '../actions/actionTypes';
import {Simulate} from "react-dom/test-utils";

const initialState = {
    clients: [],
    selectedClient: null,
    loading: false,
    submitting: false,
    error: null
};

const selectClient = (state: any, action: any) => {
    return updateObject(state, {selectedClient: action.selectedClient});
};

const fetchClientsStart = (state: any, action: any) => {
    return updateObject(state, {loading: true});
};

const fetchClientsSuccess = (state: any, action: any) => {
    return updateObject(state, {
        clients: action.clients,
        loading: false
    });
};

const createClientStart = (state: any, action: any) => {
    return updateObject(state, {submitting: true});
};

const createClientSuccess = (state: any, action: any) => {
    let updatedClientsList = [...state.clients, action.client];
    updatedClientsList = sortClients(updatedClientsList);

    return updateObject(state, {
        clients: updatedClientsList,
        submitting: false
    });
};

const updateClientStart = (state: any, action: any) => {
    return updateObject(state, {submitting: true});
};

const updateClientSuccess = (state: any, action: any) => {

    let updatedClientsList = [...state.clients];
    let index = updatedClientsList.map(c => c.id).indexOf(action.client.id);
    if (index !== -1) {
        updatedClientsList[index] = action.client;
    }

    updatedClientsList = sortClients(updatedClientsList);

    return updateObject(state, {
        clients: updatedClientsList,
        selectedClient: action.client,
        submitting: false
    });
};

const updateClientFail = (state: any, action: any) => {
    return updateObject(state, {
        error: action.error,
        submitting: false
    });
};

const sortClients = (clients: any[]) => {
    clients.sort(function (a, b) {
        const aLastname = a.lastname.toLowerCase();
        const bLastname = b.lastname.toLowerCase();
        if (aLastname > bLastname) {
            return 1;
        } else if (aLastname < bLastname) {
            return -1;
        } else {
            const aFirstname = a.firstname.toLowerCase();
            const bFirstname = b.firstname.toLowerCase();
            if (aFirstname > bFirstname) {
                return 1;
            } else if (aFirstname < bFirstname) {
                return -1;
            } else {
                return 0;
            }
        }
    });

    return clients;
};

const loadClientDetailStart = (state: any, action: any) => {
    return updateObject(state, {loading: true});
};

const loadClientDetailSuccess = (state: any, action: any) => {
    return updateObject(state, {
        selectedClient: action.client,
        loading: false
    });
};

const deleteClientStart = (state: any, action: any) => {
    return updateObject(state, {submitting: true});
};

const deleteClientSuccess = (state: any, action: any) => {
    let updatedClientsList = [...state.clients];
    let index = updatedClientsList.map(c => c.id).indexOf(action.clientId);
    if (index !== -1) {
        updatedClientsList.splice(index, 1);
    }

    return updateObject(state, {
        clients: updatedClientsList,
        selectedClient: null,
        submitting: false
    });
};

const reducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.FETCH_CLIENTS_START:
            return fetchClientsStart(state, action);
        case actionTypes.SELECT_CLIENT:
            return selectClient(state, action);
        case actionTypes.FETCH_CLIENTS_SUCCESS:
            return fetchClientsSuccess(state, action);

        case actionTypes.CREATE_NEW_CLIENT_START:
            return createClientStart(state, action);
        case actionTypes.CREATE_NEW_CLIENT:
            return createClientSuccess(state, action);

        case actionTypes.UPDATE_CLIENT_START:
            return updateClientStart(state, action);
        case actionTypes.UPDATE_CLIENT:
            return updateClientSuccess(state, action);
        case actionTypes.UPDATE_CLIENT_FAIL:
            return updateClientFail(state, action);

        case actionTypes.LOAD_CLIENT_DETAIL_START:
            return loadClientDetailStart(state, action);
        case actionTypes.LOAD_CLIENT_DETAIL_SUCCESS:
            return loadClientDetailSuccess(state, action);

        case actionTypes.DELETE_CLIENT_START:
            return deleteClientStart(state, action);
        case actionTypes.DELETE_CLIENT_SUCCESS:
            return deleteClientSuccess(state, action);

        default:
            return state;
    }
};

export default reducer;
