import React, {BaseSyntheticEvent, Component} from "react";
import {Nav, Navbar} from "react-bootstrap";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {logout} from "../../store/actions/auth";

class TopNavbar extends Component<any, any> {

    state = {
        navBarExpanded: false
    };

    ref: any = null;

    onToggleNavBar = () => {
        this.bindHandleClickOutside();
        this.ref = React.createRef();
        this.setState({navBarExpanded: true})
    };

    closeNav = () => {
        this.setState({navBarExpanded: false})
    };

    logout = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();

        this.props.onLogout();
    };

    handleClickOutside = (event: any) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({navBarExpanded: false})
            this.unbindHandleClickOutside();
        }
    };

    bindHandleClickOutside = () => {
        // Bind the event listener
        document.addEventListener("mousedown", this.handleClickOutside);
    };

    unbindHandleClickOutside = () => {
        document.removeEventListener("mousedown", this.handleClickOutside);
    };

    render() {

        let links = null;

        if (!this.props.isAuthenticated) {
            links = (
                <>
                    <Link to="/login" onSelect={this.closeNav} className="nav-link">Log in</Link>
                </>
            );
        } else {
            links = (
                <>
                    <Link to="/clients" onSelect={this.closeNav} className="nav-link">Mijn Klanten</Link>
                    <a className="nav-link" onClick={(e: BaseSyntheticEvent) => this.logout(e)} href="/logout">Uitloggen</a>
                </>
            );
        }

        return (
            <Navbar ref={this.ref} bg="light" expand="lg" expanded={this.state.navBarExpanded}
                    onToggle={this.onToggleNavBar}>
                <Navbar.Brand href="#home">marktasker</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" onClick={this.closeNav}>
                        {links}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onLogout: () => dispatch(logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavbar);
