import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import { Provider } from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import clientReducer from './store/reducers/client';
import addressReducer from './store/reducers/address';
import authReducer from './store/reducers/auth';
import thunk from 'redux-thunk';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

// Is a list of middlewares, that will be executed in order
const composeEnhancers = process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose) : null || compose;

const rootReducer = combineReducers({
    client: clientReducer,
    address: addressReducer,
    auth: authReducer
});

/**
 * Redux Thunk (A middleware):
 * Generally, this is a library which adds a middleware to your project which allows your
 * actions (or your action creators to be precise) to not return the action itself but return a
 * function which will eventually dispatch an action. With this little trick,
 * not returning the action itself but a function which will then dispatch one,
 * we can run asynchronous code because the eventually dispatched one part is the part which may run asynchronously,
 */
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));


const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(
    app,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
