import React, {Component, Fragment} from "react";
import { Container } from "react-bootstrap";
import TopNavbar from "../../components/TopNavbar/TopNavbar";


class Layout extends Component {
    render() {
        return (
            <Fragment>
                <header>
                    <TopNavbar/>
                </header>
                <Container fluid>
                    {this.props.children}
                </Container>
            </Fragment>
        );
    }
}

export default Layout;
