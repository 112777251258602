
import {
    FETCH_ADDRESSES_FAIL, FETCH_ADDRESSES_START,
    FETCH_ADDRESSES_SUCCESS, RESET_ADDRESSES
} from "./actionTypes";
import {externalInstance as axios} from "../../axios-settings";

export const fetchAddressesSuccess = ( addresses: any[] ) => {
    return {
        type: FETCH_ADDRESSES_SUCCESS,
        addresses: addresses
    };
};

export const fetchAddressesFail = ( error: any ) => {
    return {
        type: FETCH_ADDRESSES_FAIL,
        error: error
    };
};

export const fetchAddressesStart = () => {
    return {
        type: FETCH_ADDRESSES_START
    };
};

export const fetchAddresses = (searchValue: string) => {
    return (dispatch: any) => {
        dispatch(fetchAddressesStart());

        const params = {
            apiKey: 'hel4mZx27ow_EKCha5XEWFFVYpViNTtTc2tf2TowghU',
            lang: 'nl',
            q: searchValue
        };

        axios.get( '', {
            params: params
        })
            .then( res => {
                dispatch(fetchAddressesSuccess(res.data.items));
            } )
            .catch( err => {
                // dispatch(fetchAddressesFail(err));
                // TODO:
            } );
    };
};

export const resetAddresses = () => {
    return {
        type: RESET_ADDRESSES,
        addresses: []
    };
};