import {Component} from "react";

class Profile extends Component {
    render() {
        console.log('Profile page test');


        return (
            <div>Profile page</div>
        );
    }
}

export default Profile;
