import classes from "./SearchInput.module.scss";
import React, {SyntheticEvent, useEffect, useRef, useState} from "react";

interface ISearchInput {
    list: any[];
    onItemSelected(selectedItem: any): void;
    onClose?(): void;
    onSearch(searchResult: string): void;
    searchDelay?: number;
    placeholder?: string
    label?: string
    value?: string
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, setSearchState: any, onClose: any) {

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                // alert("You clicked outside of me!");
                setSearchState({isShown: false});
                if (onClose) {
                    onClose();
                }
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setSearchState, onClose]);
}

let isValid = false;

const SearchInput = (props: ISearchInput) => {

    const [searchState, setSearchState] = useState({isShown: false});
    const [inputState, setInputState] = useState({value: '', selectedItem: null});
    const [searchDelayState, setSearchDelayState] = useState(
        {
            delay: props.searchDelay !== undefined ? props.searchDelay : 500,
            timeOut: undefined
        }
    );

    let inputValue =  props.value ? props.value : '';
    useEffect(() => {
        // set initial value here, during init will be late
        setInputState({value: inputValue, selectedItem: null});
    }, [inputValue]);

    // Works like a destructor in class component
    useEffect(() => {
        return () => {
            isValid = false;
        }
    }, []);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setSearchState, props.onClose);

    const focus = (e: SyntheticEvent) => {
        setSearchState({isShown: true});
    };

    const onSelect = (e: SyntheticEvent, selectedItem: any) => {

        const newSelectedItem = {value: selectedItem.name, selectedItem: selectedItem};
        setInputState(newSelectedItem);

        setSearchState({isShown: false});

        props.onItemSelected(selectedItem);

        if (props.onClose) {
            props.onClose();
        }

        isValid = true;
    };

    let results;
    if (searchState.isShown) {
        results = (
            <div className={classes.searchContainer}>
                <div className={classes.searchResults}>
                    {props.list.map((listItem: any) => (
                        <div key={listItem.id}
                             onClick={(e) => onSelect(e, listItem)}
                             className={classes.resultItem}>{listItem.name}</div>))}
                </div>
            </div>
        );
    }

    const handleChange = (e: any) => {
        const searchValue = e.target.value.trimLeft();
        setInputState({...inputState, value: searchValue});

        if (searchDelayState.timeOut) {
            clearTimeout(searchDelayState.timeOut);
        }

        let delayTimeOut: any = setTimeout(() => {
            props.onSearch(searchValue);
            setSearchDelayState({...searchDelayState, timeOut: undefined})
        }, searchDelayState.delay);

        setSearchDelayState({...searchDelayState, timeOut: delayTimeOut})
        isValid = false;
    };

    return (
        <div>
            {props.label ? <label className="form-label">{props.label}</label> : ''}

            <div ref={wrapperRef}>
                <input type="text"
                       onFocus={focus}
                       placeholder={props.placeholder ? props.placeholder : ''}
                       className={'form-control' + (isValid ? ' is-valid' : '')}
                       value={inputState.value}
                       onChange={handleChange}/>

                {results}
            </div>
        </div>
    );
};

export default SearchInput;