import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';

const initialState = {
    token: null,
    user: null,
    error: null,
    refreshError: null,
    loading: true,
    authRedirectPath: '/',
    redirectedFrom: null
};

const authStart = (state: any, action: any) => {
    return updateObject(state, {error: null, loading: true});
};

const authSuccess = (state: any, action: any) => {
    return updateObject(state, {
        token: action.token,
        user: action.user,
        error: null,
        refreshError: null,
        loading: false
    });
};

const authFail = (state: any, action: any) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const authRefreshFail = (state: any, action: any) => {
    return updateObject(state, {
        refreshError: action.refreshError,
        redirectedFrom: action.redirectedFrom,
        loading: false,
        token: null,
        user: null
    });
};

const authRemoveRedirect = (state: any, action: any) => {
    return updateObject(state, {
        redirectedFrom: null
    });
};

const authLogout = (state: any, action: any) => {
    return updateObject(state, initialState);
};

const reducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_REFRESH_FAIL:
            return authRefreshFail(state, action);
        case actionTypes.AUTH_REMOVE_REDIRECT:
            return authRemoveRedirect(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;