import axios from '../../axios-settings';
import * as actionTypes from '../actions/actionTypes';

export const selectClientSuccess = (selectedClient: any) => {
    return {
        type: actionTypes.SELECT_CLIENT,
        selectedClient: selectedClient
    }
};

export const fetchClientsSuccess = (clients: any[]) => {
    return {
        type: actionTypes.FETCH_CLIENTS_SUCCESS,
        clients: clients
    };
};

export const fetchClientsFail = (error: any) => {
    return {
        type: actionTypes.FETCH_CLIENTS_FAIL,
        error: error
    };
};

export const fetchClientsStart = () => {
    return {
        type: actionTypes.FETCH_CLIENTS_START
    };
};


export const fetchClients = (token: string) => {
    return (dispatch: any) => {
        dispatch(fetchClientsStart());

        axios.get('/clients', {
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                dispatch(fetchClientsSuccess(res.data.clients));
            })
            .catch(err => {
                // dispatch(fetchOrdersFail(err));
                // TODO:
            });
    };
};

export const selectClient = (client: any) => {
    return (dispatch: any) => {
        dispatch(selectClientSuccess(client));
    }
};


export const createClientStart = () => {
    return {
        type: actionTypes.CREATE_NEW_CLIENT_START
    };
};

export const createClientSuccess = (client: any) => {
    return {
        type: actionTypes.CREATE_NEW_CLIENT,
        client: client
    };
};

export const createNewClient = (token: string, formData: any, closeModalHandler: any) => {
    return (dispatch: any) => {
        dispatch(createClientStart());

        axios.post('/clients',
            {...formData},
            {
                headers: {
                    Authorization: token
                }
            })
            .then(response => {
                let data = response.data;

                closeModalHandler();
                dispatch(createClientSuccess(data.client));
            })
            .catch(err => {
                // dispatch(authFail(err));
            })
    }
};


export const updateClientStart = () => {
    return {
        type: actionTypes.UPDATE_CLIENT_START
    };
};

export const updateClientSuccess = (client: any) => {
    return {
        type: actionTypes.UPDATE_CLIENT,
        client: client
    };
};

export const updateClientFail = (err: any) => {
    return {
        type: actionTypes.UPDATE_CLIENT_FAIL,
        error: err
    };
};

export const updateClient = (token: string, clientId: string, formData: any, closeModalHandler: any) => {
    return (dispatch: any) => {
        dispatch(updateClientStart());

        axios.put(`/clients/${clientId}`,
            {...formData},
            {
                headers: {
                    Authorization: token
                }
            })
            .then(response => {
                let data = response.data;
                closeModalHandler();
                dispatch(updateClientSuccess(data.client));
            })
            .catch(err => {
                dispatch(updateClientFail(err));
            })
    }
};


export const loadClientDetailFail = (error: any, notFoundClientErrorHandler: any) => {

    notFoundClientErrorHandler();

    return {
        type: actionTypes.LOAD_CLIENT_DETAIL_FAIL,
        error: error
    };
};

export const loadClientDetailStart = () => {
    return {
        type: actionTypes.LOAD_CLIENT_DETAIL_START
    };
};

export const loadClientDetailSuccess = (client: any) => {
    return {
        type: actionTypes.LOAD_CLIENT_DETAIL_SUCCESS,
        client: client
    };
};

export const loadClientDetail = (clientId: string, token: string, notFoundClientErrorHandler: any) => {
    return (dispatch: any) => {
        dispatch(loadClientDetailStart());

        axios.get('/clients/' + clientId , {
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                dispatch(loadClientDetailSuccess(res.data.client));
            })
            .catch(err => {
                dispatch(loadClientDetailFail(err, notFoundClientErrorHandler));
            });
    };
};



export const deleteClientFail = (error: any) => {
    return {
        type: actionTypes.DELETE_CLIENT_FAIL,
        error: error
    };
};

export const deleteClientStart = () => {
    return {
        type: actionTypes.DELETE_CLIENT_START
    };
};

export const deleteClientSuccess = (clientId: string) => {
    return {
        type: actionTypes.DELETE_CLIENT_SUCCESS,
        clientId: clientId
    };
};

export const deleteClient = (token: string, clientId: string, handleDeleteClient: any) => {
    return (dispatch: any) => {
        dispatch(deleteClientStart());

        axios.delete('/clients/' + clientId , {
            headers: {
                Authorization: token
            }
        })
            .then(res => {
                dispatch(deleteClientSuccess(clientId));
                handleDeleteClient();
            })
            .catch(err => {
                dispatch(deleteClientFail(err));
            });
    };
};