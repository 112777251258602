import {useFormik} from "formik";
import React, {SyntheticEvent} from "react";
import * as yup from "yup";
import styles from "./Auth.module.scss";
import {auth} from "../../store/actions/auth";
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';

const schema = yup.object({
    email: yup.string().required('E-mail is verplicht').email('Verkeerd email formaat'),
    password: yup.string().required('Wachtwoord is verplicht')
});

const Auth = (props: any) => {

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: schema,
        onSubmit: (formValues: any) => {
            props.onAuth(formValues.email, formValues.password);
        },
    });

    // This function handles the changes and marks field as touched when user starts typing
    const onChangeField = (e: SyntheticEvent, fieldName: string) => {
        formik.setFieldTouched(fieldName);
        formik.handleChange(e);
    };

    let authRedirect = null;
    if (props.isAuthenticated) {
        authRedirect = <Redirect to='/clients'/>
    }

    let errorMessage;
    if (props.error && props.error.response.status) {
        errorMessage = (
            <div className="row">
                <div className="col-12">
                    <div className="alert alert-danger" role="alert">
                        Verkeerde combinatie van email en wachtwoord
                    </div>
                </div>
            </div>
        );
    }

    return (

        <div className={styles.Auth}>
            {authRedirect}

            <h3>Log in</h3>

            {errorMessage}

            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="form-row">
                    <div className="form-group col-12">
                        <label className="form-label">E-mail</label>
                        <input type="email"
                               className={'form-control' + (
                                   formik.touched.email && !formik.errors.email
                                       ? ' is-valid'
                                       : !!(formik.touched.email && formik.errors.email) ? ' is-invalid' : ''
                               )}
                               name="email"
                               value={formik.values.email}
                               onBlur={formik.handleBlur}
                               onChange={(e) => onChangeField(e, 'email')}/>
                        <div className="invalid-feedback">{formik.errors.email}</div>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-12">
                        <label className="form-label">Wachtwoord</label>
                        <input type="password"
                               className={'form-control' + (
                                   formik.touched.password && !formik.errors.password
                                       ? ' is-valid'
                                       : !!(formik.touched.password && formik.errors.password) ? ' is-invalid' : ''
                               )}
                               name="password"
                               value={formik.values.password}
                               onBlur={formik.handleBlur}
                               onChange={(e) => onChangeField(e, 'password')}/>
                        <div className="invalid-feedback">{formik.errors.password}</div>
                    </div>
                </div>

                <div className={styles.btnFooter}>
                    <button type="submit"
                            disabled={!formik.isValid || props.loading}
                            className="btn btn-primary">Log in
                    </button>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onAuth: (email: any, password: any) => dispatch(auth(email, password))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);