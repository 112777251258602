import {BaseSyntheticEvent} from "react";

export const updateObject = (oldObject: any, updatedProperties: any) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value: any, rules: any ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
};

// Changes the state of input element and form
export const inputChangedHandler = (event: BaseSyntheticEvent, inputIdentifier: string, self: any, formName: string) => {

    if (!self.state[formName]) {
        return;
    }

    // update field values
    const updatedFormElement = updateObject(self.state[formName][inputIdentifier], {
        value: event.target.value,
        valid: checkValidity(event.target.value, self.state[formName][inputIdentifier].validation),
        touched: true
    });

    // update field within form
    const updatedClientForm = updateObject(self.state[formName], {
        [inputIdentifier]: updatedFormElement
    });

    // Set form valid/invalid
    let formIsValid = true;
    for (let inputIdentifier in updatedClientForm) {
        if (!updatedClientForm.hasOwnProperty(inputIdentifier)) {
            continue;
        }
        formIsValid = updatedClientForm[inputIdentifier].valid && formIsValid;
    }
    self.setState({[formName]: updatedClientForm, formIsValid: formIsValid});
};
