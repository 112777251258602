import React, {SyntheticEvent, useEffect, useState} from "react";
import {Modal, Button, Form, Col} from "react-bootstrap";
import * as yup from "yup";
import {useFormik} from "formik";
import SearchInput from "../../../../components/UI/SearchInput/SearchInput";
import {fetchAddresses, resetAddresses} from "../../../../store/actions/address";
import {connect} from "react-redux";
import {createNewClient, deleteClient, updateClient} from "../../../../store/actions/client";
import {VscTrash} from "react-icons/all";
import {Redirect, Switch} from "react-router";

const phoneRegExp = /^(\+|[0-9])[0-9]{8,12}$/;

const schema = yup.object({
    firstname: yup.string()
        .required('Voornaam is verplicht')
        .min(2, 'Minimaal 2 tekens vereist')
        .max(35, 'Maximaal 35 tekens toegelaten'),
    lastname: yup.string()
        .required('Achternaam is verplicht')
        .min(2, 'Minimaal 2 tekens vereist')
        .max(35, 'Maximaal 35 tekens toegelaten'),
    email: yup.string()
        .email('Ongeldige email formaat')
        .max(50, 'Maximaal 50 tekens toegelaten'),
    gsm: yup
        .string()
        .matches(phoneRegExp, 'Ongeldige GSM formaat'),
    hiddenAddress: yup.string()
});

let selectedAddress: any = null;

interface IModalState {
    isOpen: boolean
    clientToEdit: any
    onDeleteRedirect: any
}

const NewClientModal = (props: any) => {

    const [modalState, setModalState] = useState<IModalState>({
        isOpen: false,
        clientToEdit: null,
        onDeleteRedirect: null
    });

    let formikData;
    let deleteBtn;
    let modalTitle = 'Klant aanamaken';

    if (modalState.clientToEdit) {
        formikData = {
            initialValues: {
                firstname: modalState.clientToEdit.firstname,
                lastname: modalState.clientToEdit.lastname,
                email: modalState.clientToEdit.email,
                gsm: modalState.clientToEdit.gsm,
                hiddenAddress: modalState.clientToEdit.address ? modalState.clientToEdit.address.fullName : ''
            },
            validationSchema: schema,
            enableReinitialize: true, // without this initial values won't set
            onSubmit: (formValues: any) => {
                let toSubmitData = getFormData(formValues);
                props.onUpdateClient(props.token, modalState.clientToEdit.id, toSubmitData, closeModal);
            },
        };

        modalTitle = 'Klant aanpassen';
        deleteBtn = (
            <button onClick={() => deleteClient()}
                    disabled={props.submitting}
                    type="button"
                    className="btn btn-danger"><VscTrash/></button>
        );
    } else {
        formikData = {
            initialValues: {
                firstname: "",
                lastname: "",
                email: "",
                gsm: "",
                hiddenAddress: ""
            },
            validationSchema: schema,
            onSubmit: (formValues: any) => {

                let toSubmitData = getFormData(formValues);

                props.onCreateNewClient(props.token, toSubmitData, closeModal);
            },
        };
    }

    const formik = useFormik(formikData);

    const getFormData = (formValues: any) => {
        let toSubmitData;
        if (selectedAddress) {
            toSubmitData = {
                ...formValues,
                ...selectedAddress
            };
        } else {

            if (formValues.hiddenAddress === false) {
                toSubmitData = {
                    ...formValues,
                    address: false
                };
            } else {
                toSubmitData = {
                    ...formValues
                };
            }
        }

        return toSubmitData;
    };

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        props.onOpen(openModal);
    });

    const openModal = (client: any = null) => {
        let clientToEdit = client ? client : null;
        setModalState({isOpen: true, clientToEdit: clientToEdit, onDeleteRedirect: null});
    };

    const closeModal = () => {
        setModalState({isOpen: false, clientToEdit: null, onDeleteRedirect: null});
        formik.resetForm();
    };

    // This function handles the changes and marks field as touched when user starts typing
    const onChangeField = (e: SyntheticEvent, fieldName: string) => {
        formik.setFieldTouched(fieldName);
        formik.handleChange(e);
    };

    let addresses = [];
    let addressesLength = props.addresses.length;
    for (let i = 0; i < addressesLength; i++) {
        addresses.push({
            id: i,
            name: props.addresses[i].address.label,
            addressObject: props.addresses[i]
        })
    }

    const onAddressSelected = (address: any) => {
        formik.setFieldValue('hiddenAddress', address.name);

        selectedAddress = {
            latitude: address.addressObject.position.lat,
            longitude: address.addressObject.position.lng,
            postcode: address.addressObject.address.postalCode,
            city: address.addressObject.address.city,
            country: address.addressObject.address.countryName,
            countryCode: address.addressObject.address.countryCode,
            address: address.addressObject.address.label
        };
    };

    const onSearchAddress = (searchValue: string) => {
        if (searchValue === '') {
            formik.setFieldValue('hiddenAddress', false);
            props.onResetAddresses();
            return;
        }
        props.onFetchAddresses(searchValue);
    };

    const onCloseAddress = () => {
        props.onResetAddresses();
    };

    const deleteClient = () => {
        let confirmDeletion = window.confirm('Wilt u deze klant zeker verwijderen?');
        if (confirmDeletion) {
            props.onDeleteClient(props.token, modalState.clientToEdit.id, handleDeleteClient);
        }
    };

    const handleDeleteClient = () => {
        closeModal();

        let onDeleteRedirect = (
            <Switch>
                <Redirect to='/clients'/>
            </Switch>
        );
        setModalState({...modalState, onDeleteRedirect: onDeleteRedirect});
    };

    return (
        <>
            {modalState.onDeleteRedirect}
            <Modal
                show={modalState.isOpen}
                onHide={closeModal}>

                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="firstname">
                                <Form.Label>Voornaam*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstname"
                                    value={formik.values.firstname}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => onChangeField(e, 'firstname')}
                                    isValid={formik.touched.firstname && !formik.errors.firstname}
                                    isInvalid={!!(formik.touched.firstname && formik.errors.firstname)}
                                />
                                <Form.Control.Feedback
                                    type={"invalid"}>{formik.errors.firstname}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="lastname">
                                <Form.Label>Achternaam*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastname"
                                    value={formik.values.lastname}
                                    onChange={(e) => onChangeField(e, 'lastname')}
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.lastname && !formik.errors.lastname}
                                    isInvalid={!!(formik.touched.lastname && formik.errors.lastname)}
                                />
                                <Form.Control.Feedback
                                    type={"invalid"}>{formik.errors.lastname}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={(e) => onChangeField(e, 'email')}
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.email && !formik.errors.email && formik.values.email.trim().length > 0}
                                    isInvalid={!!(formik.touched.email && formik.errors.email)}
                                />
                                <Form.Control.Feedback
                                    type={"invalid"}>{formik.errors.email}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="gsm">
                                <Form.Label>Gsm</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="gsm"
                                    value={formik.values.gsm}
                                    onChange={(e) => onChangeField(e, 'gsm')}
                                    onBlur={formik.handleBlur}
                                    isValid={formik.touched.gsm && !formik.errors.gsm && formik.values.gsm.trim().length > 0}
                                    isInvalid={!!(formik.touched.gsm && formik.errors.gsm)}
                                />
                                <Form.Control.Feedback
                                    type={"invalid"}>{formik.errors.gsm}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>

                        <input type="hidden"
                               name="hiddenAddress"
                               value={formik.values.hiddenAddress}/>
                    </Form>

                    <SearchInput list={addresses}
                                 value={formik.values.hiddenAddress}
                                 onItemSelected={(selectedAddress: any) => onAddressSelected(selectedAddress)}
                                 onSearch={(searchResult: string) => onSearchAddress(searchResult)}
                                 placeholder={"Zoek adres"}
                                 onClose={onCloseAddress}
                                 label={"Adres"}
                    />
                </Modal.Body>
                <Modal.Footer className="d-block">
                    <div className="d-flex justify-content-between">
                        <div>
                            {deleteBtn}
                        </div>

                        <div>
                            <Button variant="secondary" onClick={closeModal}>
                                Sluiten
                            </Button>
                            <Button variant="primary"
                                    className="ml-2"
                                    onClick={() => formik.handleSubmit()}
                                    disabled={!formik.dirty || !formik.touched || !formik.isValid || props.submitting}>
                                {modalState.clientToEdit ? 'Opslaan' : 'Creëren'}
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        addresses: state.address.addresses,
        token: state.auth.token,
        submitting: state.client.submitting,
        error: state.client.error
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onFetchAddresses: (searchValue: string) => dispatch(fetchAddresses(searchValue)),
        onResetAddresses: () => dispatch(resetAddresses()),
        onCreateNewClient: (token: string, formData: any, closeModalHandler: any) =>
            dispatch(createNewClient(token, formData, closeModalHandler)),
        onUpdateClient: (token: string, clientId: string, formData: any, closeModalHandler: any) =>
            dispatch(updateClient(token, clientId, formData, closeModalHandler)),
        onDeleteClient: (token: string, clientId: string, handleDeleteClient: any) =>
            dispatch(deleteClient(token, clientId, handleDeleteClient))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewClientModal);
