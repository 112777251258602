import React, {Component, Fragment} from "react";
import {Route} from "react-router";
import ClientDetail from "./ClientDetail/ClientDetail";
import {Switch, withRouter} from "react-router-dom";
import ClientsList from "./ClientsList/ClientsList";

class Clients extends Component<any, any> {
    render() {
        return (
            <Fragment>
                <Switch>
                    <Route path={this.props.match.url + "/:id"} exact>
                        <ClientDetail/>
                    </Route>
                    <Route path={this.props.match.url} exact>
                        <ClientsList/>
                    </Route>
                </Switch>
            </Fragment>
        );
    }
}

export default withRouter(Clients);
